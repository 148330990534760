import React from "react"


import Section from "../layout/section"
import { FixedContainer } from "../layout/container"

export default () => (
  <Section>
    <FixedContainer>
      <h4 className="title is-4">Using ICECAP</h4>
      <p></p>
        <h6 className="subtitle is-6">Downloading meteorology and emission files</h6>

        <p>The following table summarizes the ICECAP meteorology presently available for download from the GISS-GC server.</p>

        <table class="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th class="has-text-left">Scenario</th>
              <th class="has-text-centered">∆T<sub>s</sub> (<sup>o</sup>C)</th>
              <th class="has-text-centered">Years Available</th>
              <th class="has-text-centered">Topography</th>
              <th class="has-text-centered">Orbital Forcing</th>
              <th class="has-text-centered">SSTs</th>
              <th class="has-text-centered">CO<sub>2</sub> (ppmv)</th>
              <th class="has-text-centered">CH<sub>4</sub> (ppbv)</th>
              <th class="has-text-centered">N<sub>2</sub>O (ppbv)</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
            <th class="has-text-left">Scenario</th>
            <th class="has-text-centered">∆T<sub>s</sub> (<sup>o</sup>C)</th>
            <th class="has-text-centered">Years Available</th>
            <th class="has-text-centered">Topography</th>
            <th class="has-text-centered">Orbital Forcing</th>
            <th class="has-text-centered">SSTs</th>
            <th class="has-text-centered">CO<sub>2</sub> (ppmv)</th>
            <th class="has-text-centered">CH<sub>4</sub> (ppbv)</th>
            <th class="has-text-centered">N<sub>2</sub>O (ppbv)</th>
            </tr>
          </tfoot>
          <tbody>
            <tr>
              <td>Present Day (PD)</td>
              <td class="has-text-centered">0.0</td>
              <td class="has-text-centered">6</td>
              <td class="has-text-centered">Modern</td>
              <td class="has-text-centered">-0.05 ka (2000 C.E.)</td>
              <td class="has-text-centered">CMIP6</td>
              <td class="has-text-centered">369</td>
              <td class="has-text-centered">1778</td>
              <td class="has-text-centered">316</td>
            </tr>
            <tr>
              <td>Preindustrial (PI)</td>
              <td class="has-text-centered">-0.7</td>
              <td class="has-text-centered">6</td>
              <td class="has-text-centered">Modern</td>
              <td class="has-text-centered">0.1 ka (1850 C.E.)</td>
              <td class="has-text-centered">CMIP6</td>
              <td class="has-text-centered">284</td>
              <td class="has-text-centered">808</td>
              <td class="has-text-centered">273</td>
            </tr>
            <tr>
              <td>Mid-Holocene (MH)</td>
              <td class="has-text-centered">-0.6</td>
              <td class="has-text-centered">6</td>
              <td class="has-text-centered">Modern</td>
              <td class="has-text-centered">6 ka</td>
              <td class="has-text-centered">PMIP4</td>
              <td class="has-text-centered">264</td>
              <td class="has-text-centered">597</td>
              <td class="has-text-centered">262</td>
            </tr>
            <tr>
              <td>Last Glacial Maximum (LGM)</td>
              <td class="has-text-centered">-4.7</td>
              <td class="has-text-centered">6</td>
              <td class="has-text-centered">LICC</td>
              <td class="has-text-centered">21 ka</td>
              <td class="has-text-centered">PMIP4</td>
              <td class="has-text-centered">188</td>
              <td class="has-text-centered">375</td>
              <td class="has-text-centered">200</td>
            </tr>
            <tr>
              <td>Last Glacial Maximum (LGM2) </td>
              <td class="has-text-centered">-5.7</td>
              <td class="has-text-centered">6</td>
              <td class="has-text-centered">LICC</td>
              <td class="has-text-centered">21 ka</td>
              <td class="has-text-centered"><a href="https://github.com/jesstierney/lgmDA">Assimilated</a></td>
              <td class="has-text-centered">188</td>
              <td class="has-text-centered">375</td>
              <td class="has-text-centered">200</td>
            </tr>
            <tr>
              <td>Last Interglacial (LIG)</td>
              <td class="has-text-centered">-0.4</td>
              <td class="has-text-centered">6</td>
              <td class="has-text-centered">Modern</td>
              <td class="has-text-centered">127 ka</td>
              <td class="has-text-centered">PMIP4</td>
              <td class="has-text-centered">275</td>
              <td class="has-text-centered">685</td>
              <td class="has-text-centered">255</td>
            </tr>
          </tbody>
        </table>

        <p>Additional scenarios can be generated by email request to
        {` `}
        <a href="mailto:lee.murray@rochester.edu?subject='ICECAP 2.0'">Lee Murray</a>.
        </p>

      </FixedContainer>
    </Section>
)

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { Link } from "gatsby"

import { FixedContainer } from "./container"

const Breadcrumbs = ({ breadcrumbs }) => {

  const lastIndex = breadcrumbs.length - 1
  return (
    <nav className="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
      <FixedContainer>
        <ul>
          {breadcrumbs && breadcrumbs.map((crumb, index) => {
            if (index === lastIndex) {
              return (
                <li key={crumb.key} className="is-active">
                  <a href="#" aria-current="page" aria-label={crumb.title} dangerouslySetInnerHTML={{__html: crumb.title}} />
                </li>
              )
            }
            return (
              <li key={crumb.key}>
                <Link to={crumb.path} dangerouslySetInnerHTML={{__html: crumb.title}} />
              </li>
            )
          })}
        </ul>
      </FixedContainer>
    </nav>
  )
}

export default Breadcrumbs
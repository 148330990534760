import React from "react"


import Section from "../layout/section"
import { FixedContainer } from "../layout/container"

export default () => (
  <Section>
    <FixedContainer>
      <h4 className="title is-4">About</h4>
      <article>
        <p>GISS-GC is an NSF and NASA-funded project that couples the NASA Goddard Institute for Spaces Studies (GISS)
        {` `}
        <a href="https://www.giss.nasa.gov/tools/modelE/">ModelE</a>
        {` `}
        general circulation model with the
        {` `}
        <a href="http://geos-chem.org">GEOS-Chem</a>
        {` `}
        chemical transport model.
        The goal of GISS-GC is to facilitate the study of climate-chemistry interactions
        across Earth's history and into its future.
        </p>
        <p>There are three configurations of GISS-GC in development:</p>
      </article>
    </FixedContainer>
  </Section>
)

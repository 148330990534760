import React from "react"
import { graphql } from "gatsby"

import ChildPage from "../components/layout/page"
import About from "../components/giss-gc/About"
import GISSGCUsersComponent from "../components/giss-gc/Users"
import GISSGCConfigurations from "../components/giss-gc/Configurations"
import Using from "../components/giss-gc/Using"
import ICECAP from "../components/giss-gc/ICECAP"
import Help from "../components/giss-gc/Help"


const GISSGCPage = ({ data }) => {

  const gissgcEdges = data.allMarkdownRemark.edges

  const headerImage = data.header.publicURL

  const parallax = {
    image: headerImage,
    strength: 400
  }

  const breadcrumbs = [
    { "key": "home", "title": "Home", "path": "/" },
    { "key": "giss-gc", "title": "GISS-GC", "path": "/giss-gc" }
  ]

  return (
    <ChildPage
      title="GISS-GC"
      breadcrumbs={breadcrumbs}
      parallax={parallax}
    >
      <About />
      <GISSGCConfigurations />
      <GISSGCUsersComponent />
      <Using />
      <ICECAP />
      <Help />
    </ChildPage>
  )
}

export default GISSGCPage

export const pageQuery = graphql`
  query GISSGCPage {
    header: file(relativePath: { eq: "AdobeStock_219800385.jpg" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    allMarkdownRemark(
      filter: { fields: { type: {eq: "gissgc"} }}
    ) {
      edges {
        node {
          html
          fields {
            slug
            type
          }
        }
      }
    }
  }
`

import React from "react"
import { useStaticQuery, graphql } from "gatsby"


import Section from "../layout/section"
import { FixedContainer } from "../layout/container"
import ExternalLink from "../layout/externalLink"
import { Config } from "./config"

const GISSGCConfigurations = () => {

  const data = useStaticQuery(graphql`{
  gcap: file(relativePath: {eq: "gcap.png"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: BLURRED
      )
    }
  }
  icecap: file(relativePath: {eq: "icecap.png"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: BLURRED
      )
    }
  }
  e21gc: file(relativePath: {eq: "e21gc.png"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: BLURRED
      )
    }
  }
}
`)

  return (
    <Section>
      <FixedContainer>
        <div className="topics columns is-variable is-4">
          <Config image={data.gcap} >
            <p className="subtitle is-4">
              <strong>Global Change and Air Pollution (GCAP)</strong>
            </p>
            <p>
              GCAP is especially designed for studying the impact of future
              climate change on atmospheric composition and surface air quality.
              Meteorology is archived from the version of the GISS GCM frozen for
              the
              {` `}
              <ExternalLink path="https://www.wcrp-climate.org/wgcm-cmip/wgcm-cmip6" title="CMIP6 Homepage">CMIP6</ExternalLink>
              {` `}
              simulations. We provide meteorology at and since the preindustrial
              and for several future scenarios ranging from extreme mitigation to extreme warming.
              Users may also generate their own meteorology for driving GEOS-Chem.
              The current version is GCAP 2.0, which is described by
              {` `}
              <ExternalLink path="https://doi.org/10.5194/gmd-2021-144" title="GCAP 2.0 Description Paper">Murray et al. (2021)</ExternalLink>.
            </p>
          </Config>
          <Config image={data.icecap}>
            <p className="subtitle is-4">
              <strong>ICE age Chemistry and Proxies (ICECAP)</strong>
            </p>
            <p>
            ICECAP is designed for studying atmospheric chemistry in paleo climates
            with significantly different land covers and for interpreting ice-core records and other proxy data.
            Archived GISS meteorology is first used to drive a dynamic global vegetation model
            {` `}
            (<ExternalLink path="https://github.com/ARVE-Research/LPJ-LMfire" title="LPJ-LMfire Homepage">LPJ-LMfire</ExternalLink>)
            {` `}
            to generate consistent land cover products. We will provide a variety of products
            from the
            {` `}
            <ExternalLink path="https://pmip4.lsce.ipsl.fr/doku.php" title="PMIP4 Homepage">PMIP</ExternalLink>
            {` `}
            experiments.
            The original version of ICECAP is described by
            {` `}
            <ExternalLink path="https://doi.org/10.5194/acp-14-3589-2014" title="ICECAP 1.0 Description Paper">Murray et al. (2014)</ExternalLink>.
            {` `}
            </p>
          </Config>
          <Config image={data.e21gc}>
            <p className="subtitle is-4">
              <strong>GC online in E2.1</strong>
            </p>
            <p>
              ModelE2.1 itself includes an optional online reduced atmospheric chemistry mechanism (see
              {` `}
              <ExternalLink path="http://doi.org/10.1029/2019MS002025" title="E2.1 Description">Kelley et al., 2020</ExternalLink>
              ).
              For those who wish to study two-way chemistry-climate interactions with the more complex
              GEOS-Chem mechanism, or use a consistent chemical mechanism with sister projects (e.g.,
              {` `}
              <ExternalLink path="http://wiki.seas.harvard.edu/geos-chem/index.php/Coupling_GEOS-Chem_with_NCAR_models" title="CESM2-GC">CESM2-GC</ExternalLink>
            ) to isolate how uncertainties in climate propagate to uncertainties in composition, we are implementing GEOS-Chem as an alternative online chemical mechanism
              within E2.1.
              {` `}
              <i>Currently in beta testing</i>.
            </p>
          </Config>
        </div>
      </FixedContainer>
    </Section>
  )
}

export default GISSGCConfigurations

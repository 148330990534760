import React from "react"
import PropTypes from "prop-types"

const Section =({size, children, className}) => {
  const sizeClass = size === undefined ? "" : size
  const additionalClasses = className === undefined ? "" : className
  return (
    <section className={`section ${sizeClass} ${additionalClasses}`}>
      {children}
    </section>
  )
}

Section.propTypes = {
  size: PropTypes.string,
}

Section.defaultProps = {
  size: ''
}

export default Section
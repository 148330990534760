import React from "react"


import Section from "../layout/section"
import { FixedContainer } from "../layout/container"

export default () => (
  <Section>
    <FixedContainer>
      <h4 className="title is-4">Using GCAP</h4>
      <p></p>
      <h6 className="subtitle is-6">Obtaining GCAP 2.0 source code</h6>

        <p>GCAP 2.0 entered the GEOS-Chem standard code in version 13.1.
        You can follow the standard
        {` `}
        <a href="http://wiki.seas.harvard.edu/geos-chem/index.php/Downloading_GEOS-Chem_source_code_(13.0.0_and_later_versions)">directions</a>
        {` `}
        for downloading the model.
        </p>
        <pre>
          <code>
          git clone https://github.com/geoschem/GCClassic.git ./Code.GCAP2{'\n'}
          cd Code.GCAP2{'\n'}
          git submodule update --init --recursive{'\n'}
          </code>
        </pre>
        <p>
          Note that submodules always download in a headless state.
          If you wish to track your changes, you will need to check out or create a branch.
        </p>
        <h6 className="subtitle is-6">Generating run directories</h6>
        <p>
          You can
          {` `}
          <a href="http://wiki.seas.harvard.edu/geos-chem/index.php/Creating_run_directories_for_GEOS-Chem_13.0.0_and_later">create run directories</a>
          {` `}
          just like in regular GEOS-Chem!
         </p>
        <h6 className="subtitle is-6">Downloading meteorology and emission files</h6>
         <p>
          GCAP 2.0 is compatable with the new python data downloader for
          {` `}
          <a href="http://wiki.seas.harvard.edu/geos-chem/index.php/Downloading_GEOS-Chem_data_directories">downloading input files</a>.
          {` `}
          </p>
          <p>Just point download_data.py to the GISS-GC repository
          <pre>
            <code>
            python3 download_data.py dryrun.log --ur{'\n'}
            </code>
          </pre>
          </p>
        <p>The following table summarizes the meteorology presently available for download from the GISS-GC server.</p>

        <table class="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th class="has-text-left">Scenario</th>
              <th class="has-text-centered">Variant Label</th>
              <th class="has-text-centered">1851-1860 C.E.</th>
              <th class="has-text-centered">2001-2014 C.E.</th>
              <th class="has-text-centered">2040-2049 C.E.</th>
              <th class="has-text-centered">2090-2099 C.E.</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th class="has-text-left">Scenario</th>
              <th class="has-text-centered">Variant Label</th>
              <th class="has-text-centered">1851-1860 C.E.</th>
              <th class="has-text-centered">2001-2014 C.E.</th>
              <th class="has-text-centered">2040-2049 C.E.</th>
              <th class="has-text-centered">2090-2099 C.E.</th>
            </tr>
          </tfoot>
          <tbody>
            <tr>
              <td>Historical</td>
              <td class="has-text-centered">r1i1p1f2</td>
              <td class="has-text-centered">&#127757;</td>
              <td class="has-text-centered">&#127758;</td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered"></td>
            </tr>
            <tr>
              <td>Historical (Nudged to MERRA-2)</td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered">&#127759;</td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered"></td>
            </tr>
            <tr>
              <td>SSP1-1.9 (Extreme Mitigation)</td>
              <td class="has-text-centered">r1i1p1f2</td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered">&#127757;</td>
              <td class="has-text-centered">&#127758;</td>
            </tr>
            <tr>
              <td>SSP1-2.6</td>
              <td class="has-text-centered">r1i1p1f2</td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered">&#127759;</td>
              <td class="has-text-centered">&#127757;</td>
            </tr>
            <tr>
              <td>SSP4-3.4 (Paris Agreement)</td>
              <td class="has-text-centered">r1i1p1f2</td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered">&#127758;</td>
              <td class="has-text-centered">&#127759;</td>
            </tr>
            <tr>
              <td>SSP2-4.5</td>
              <td class="has-text-centered">r1i1p1f2</td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered">&#127757;</td>
              <td class="has-text-centered">&#127758;</td>
            </tr>
            <tr>
              <td>SSP4-6.0</td>
              <td class="has-text-centered">r1i1p1f2</td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered">&#127759;</td>
              <td class="has-text-centered">&#127757;</td>
            </tr>
            <tr>
              <td>SSP3-7.0</td>
              <td class="has-text-centered">r1i1p1f2</td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered">&#127758;</td>
              <td class="has-text-centered">&#127759;</td>
            </tr>
            <tr>
              <td>SSP5-8.5 (Extreme Warming)</td>
              <td class="has-text-centered">r1i1p1f2</td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered"></td>
              <td class="has-text-centered">&#127757;</td>
              <td class="has-text-centered">&#127758;</td>
            </tr>
          </tbody>
        </table>

        <p>Additional scenarios and variants can be generated by email request to
        {` `}
        <a href="mailto:lee.murray@rochester.edu?subject='GCAP 2.0'">Lee Murray</a>.
        </p>

      </FixedContainer>
    </Section>
)

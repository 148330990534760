import React from "react"

import Section from "../layout/section"
import { FixedContainer } from "../layout/container"

import GoogleImage from "./GoogleImage"

export default () => (
  <Section>
    <FixedContainer>
      <h4 className="title is-4">Getting Help</h4>
      <p></p>
      <h6 className="subtitle is-6">GISS-GC Google Group</h6>
      <article>
        <p>
        The GISS-GC Google Group (giss-gc@googlegroups.com) is a place for GISS-GC users to ask questions and exchange experiences about using GCAP, ICECAP and/or GC online within E2.1.
        </p>
        <p>
        To join the Google Group, please sign into your Google account and search for GISS-GC and click 'Ask to join'.
        </p>
        <GoogleImage />
        <p>
        Your group membership should be processed by the group managers shortly.
        </p>
        <p>
        You may also find the GEOS-Chem
        {` `}
        <a href="http://acmg.seas.harvard.edu/geos/geos_working_groups.html">
        Chemistry-Ecosystem-Climate Working Group
        </a>
        {` `}
        email list useful.
        </p>
        <h4 className="title is-6">Contact Information</h4>
        <p>
          Please contact
          {` `}
          <a href="mailto:lee.murray@rochester.edu?subject='GCAP 2.0'">Lee Murray</a>
          {` `}
          with any questions or to report any issues.
        </p>
      </article>
      </FixedContainer>
    </Section>
)

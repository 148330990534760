import React from "react"

import { Helmet } from "react-helmet"

import loadable from "@loadable/component"

import Section from "../layout/section"
import { FixedContainer } from "../layout/container"

const LeafletComponent =  loadable(() => import('./leafletUsersMap'))

const GISSGCUsersComponent = () => {

  return (
    <Section>
      <FixedContainer>
      <h4 className="title is-4">Ongoing Projects</h4>
    <div className="card research">
      <div className="card-image">
        <figure className="research">
          <LeafletComponent
            div="map"
          />
        </figure>
      </div>
      <div className="card-content">
        <div className="content">
          <span className="title is-6">
            GISS-GC users around the world
          </span>
          <br />
            Click on each location to learn more about how GISS-GC is being used to study atmospheric chemistry-climate interactions.
            Please e-mail
            {` `}
            <a href="mailto:lee.murray@rochester.edu?subject=GISS-GC">Lee Murray</a>
            {` `}
            to add your project to the map!
        </div>
      </div>
    </div>
    <p></p>
    </FixedContainer>
  </Section>
  )
}

export default GISSGCUsersComponent

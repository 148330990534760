import React from "react"

const FixedContainer = ({children}) => (
  <div className="container">
    {children}
  </div>
)

const FluidContainer = ({children}) => (
  <div className="container is-fullhd">
    {children}
  </div>
)

const ColumnsContainer = ({children, className}) => (
  <div className={`columns ${className === undefined ? '' : className}`}>
    {children}
  </div>
)

const Column = ({children}) => (
  <div className="column">
    {children}
  </div>
)

export { FixedContainer, FluidContainer, ColumnsContainer, Column }
/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const Config = ({title, image, link, children}) => {

  const img  = getImage(image)

  return (
    <div className="column">
      <div className="gissgc-config">
        <div className="gissgc-config-bg">
          <GatsbyImage
            image={img}
            alt={title}
          />
        </div>
        <div className="gissgc-config-body">
          <p className="title">{title}</p>
          {link && (
            <Link to={link} className="button is-primary is-inverted is-outlined">Learn More</Link>
          )}
        </div>
      </div>
      {children && (
        <div className="gissgc-config-content">
          {children}
        </div>
      )}
    </div>
  );
}

export default Config;

import React from "react"
import PropTypes from "prop-types"
import urljoin from "url-join"

import Layout from "./layout"
import SEO from "../seo"
import Breadcrumbs from "./breadcrumbs"

import config from "../../../content/data/SiteConfig"

const ChildPage = ({ title, subtitle, headerSize, altTitle, breadcrumbs, children, structuredData, parallax}) => {

  const headerProps = {
    preTitle: "",
    title,
    subtitle,
    color: 'is-dark',
    headerSize,
    isFullHeight: false,
    parallax
  }

  const pageSchema = []
  let pagePath = "/"

  if (breadcrumbs) {
    const lastIndex = breadcrumbs.length - 1

    const itemListElement = []
    breadcrumbs.forEach((crumb, index) => {
      const crumbPath = urljoin(config.site.url, config.site.prefix, crumb.path)
      const crumbSchema = {
        "@type": "ListItem",
        position: index + 1,
        name: crumb.title,
        item: crumbPath
      }
      itemListElement.push(crumbSchema)

      if (index === lastIndex) {
        pagePath = crumbPath
      }
    })
    const breadcrumbSchema = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement
    }
    pageSchema.push(breadcrumbSchema)
  }

  if (structuredData) {
    structuredData.forEach((data) => {
      pageSchema.push(data)
    })
  }

  const pageSEO = {
    pageSchema
  }

  return (
    <Layout headerProps={headerProps}>
      <SEO 
        title={title}
        altTitle={altTitle}
        pagePath={pagePath}
        pageSEO={pageSEO} 
      />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      {children}
    </Layout>
  )
}

ChildPage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
}

ChildPage.defaultProps = {
  subtitle: ''
}

export default ChildPage
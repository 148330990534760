import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import ExternalLink from "../layout/externalLink"

export const GoogleImage = () => (
  <StaticQuery
    query={graphql`query GoogleImageFooterQuery {
  footer: file(relativePath: {eq: "googlegroup.png"}) {
    childImageSharp {
      gatsbyImageData(
        placeholder: BLURRED
      )
    }
  }
}
`}
    render={data => (
      <div className="columns">
        <div className="column">
          <div className="card research">
              <figure>
                <GatsbyImage image={getImage(data.footer)} />
              </figure>
          </div>
        </div>
        <div className="column">
        </div>
      </div>
    )}
  />
)

export default GoogleImage
